import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import classnames from "classnames";
import { useHistory } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { isUserAdmin,isUserApprover } from "components/formcomponents/utils/userUtils";

import ReactModal from "react-modal";
import BaseButton from "components/common/BaseButton";
import CircleCloseFilterSvg from "components/svg/CircleCloseFilter";
import SvgButtonBarReject from "../../svg/ButtonBarReject"
import rejectCase from "./../ButtonActions/rejectCase";

const ButtonReject = ({ buttonSize, currentFormTemplateID, setCaseStatus, approvingState: isApproving }) => {
    const intl = useIntl();
    const history = useHistory();
    const dispatch = useDispatch();

    const [isApprover, setIsApprover] = useState(false);
    const [isRejecting, setIsRejecting] = useState(false);
    const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
    const [isRejected, setIsRejected] = useState(false);

    const currentCaseStatus = useSelector(state => state.currentCase.case.status, shallowEqual);
    const currentCaseApprover = useSelector(state => state.currentCase.case.approver, shallowEqual);
    const currentCaseId = useSelector(state => state.currentCase.case.id, shallowEqual);
    const currentCaseDomain = useSelector(state => state.currentCase.case.domain, shallowEqual);
    const currentUser = useSelector(state => state.currentUser);
    const connection = useSelector(state => state.connection, shallowEqual);
    const answers = useSelector(state => state.saveComponentValue.components, shallowEqual);

    useEffect(() => {
        if (!answers || !currentUser || !currentCaseApprover || !currentCaseDomain) return;
        const isCurrentUserApprover = isUserApprover(
            answers,
            currentUser.email,
            currentCaseApprover,
            currentCaseDomain
        );
        setIsApprover(isCurrentUserApprover || isUserAdmin(currentUser['custom:administrator']));
    }, [answers, currentUser, currentCaseApprover, currentCaseDomain]);

    const openRejectConfirmation = () => {
        setIsRejecting(false);
        setIsRejectModalOpen(true);
    }

    const closeRejectModal = () => { 
        setIsRejectModalOpen(false);
        if (isRejected) {
            if (connection) {
                history.push("/my-reports");
            } else {
                history.push("/dashboard");
            }    
        }
    }

    const onRejectCase = () => { 
        setIsRejecting(true);
        rejectCase(currentCaseId,answers, currentFormTemplateID, intl, dispatch)
            .then(response => {
                setIsRejecting(false);
                if (!response.noApprover) {
                    setCaseStatus(response.caseStatus);    
                }
                setIsRejectModalOpen(true);
                setIsRejected(true);
            })
    }

    const isVisible = currentCaseStatus === "submitted" && isApprover;
    const isDisabled = !connection || isApproving;
    
    return isVisible && (
        <>
            <button
                id="buttonReject"
                type="button"
                onClick={openRejectConfirmation}
                className={classnames("button", "button--icon-only", { "button--icon-saving": isRejecting }, { "button--icon-disabled": isDisabled })}
                disabled={isDisabled}>
                <SvgButtonBarReject size={buttonSize} title={intl.formatMessage({ id: "formRenderer.reject" })} />
            </button>
            <ReactModal isOpen={isRejectModalOpen} className="react-modal" overlayClassName="react-modal-overlay" onRequestClose={() => closeRejectModal()}>
                <div className="react-modal__header">
                    {!isRejected &&
                        <button className="react-modal__close-button" onClick={() => { closeRejectModal(); }}>
                            <CircleCloseFilterSvg />
                        </button>
                    }
                </div>
                <div className="react-modal__body">
                    <div className="react-modal__text">
                        {isRejected ? <FormattedMessage id="formRenderer.resubmitAfterRejectMessage" /> : <FormattedMessage id="formRenderer.rejectConfirmation" />}
                    </div>
                    <div className="react-modal__actions-container">
                        {isRejected ?
                            <BaseButton handleSubmit={() => closeRejectModal()} text={intl.formatMessage({ id: "formRenderer.close" })} backgroundColor="green" className="react-modal__action-button" />
                            :
                            <>
                                <BaseButton handleSubmit={() => onRejectCase()} text={intl.formatMessage({ id: "formRenderer.rejectButton" })} backgroundColor="gray" className="react-modal__action-button button--wide" />
                                <BaseButton handleSubmit={() => closeRejectModal()} text={intl.formatMessage({ id: "formRenderer.edit.cancel" })} backgroundColor="green" className="react-modal__action-button" />
                            </>
                        }
                    </div>
                </div>
            </ReactModal>
        </>
    );

};

export default ButtonReject;