import Pica from 'pica';

const MAX_INPUT_CANVAS_SAFE_SIZE = 4096;

export default function resizeImage(file, body, maxImageWidth, maxImageHeight) {
    const inputCanvas = document.createElement('canvas');
    const outputCanvas = document.createElement('canvas');

        return new Promise(resolve => {
            const img = new Image();
            img.src = `data:${file.type};base64,${body}`;
            img.onload = () => {
                const { height: safeHeight, width: safeWidth } = calculateSafeInputCanvasSize(img.width, img.height);
                inputCanvas.width = safeWidth;
                inputCanvas.height = safeHeight;

                const ctx = inputCanvas.getContext('2d');
                ctx.drawImage(img, 0, 0, safeWidth, safeHeight)

                const aspectRatio = img.width / img.height;
                if (aspectRatio > 1) {
                    //Landscape
                    if (img.width > maxImageWidth) {
                        //Do resize
                        outputCanvas.width = maxImageWidth;
                        outputCanvas.height = maxImageWidth / aspectRatio;
                        resolve(resizeImageWithScaledCanvasAndImage(file, outputCanvas, inputCanvas));
                    } else {
                        resolve(skipResizing(file, body));
                    }
                } else {
                    //Portrait
                    if (img.height > maxImageHeight) {
                         //Do resize
                        outputCanvas.height = maxImageHeight;
                        outputCanvas.width = maxImageHeight * aspectRatio;
                        resolve(resizeImageWithScaledCanvasAndImage(file, outputCanvas, inputCanvas));
                    } else {
                        resolve(skipResizing(file, body));
                    }
                }
            };
        });
}

async function resizeImageWithScaledCanvasAndImage (file, outputCanvas, inputCanvas) {
    const pica = Pica();
    const result = await pica.resize(inputCanvas, outputCanvas, {
        unsharpAmount: 80,
        unsharpRadius: 0.6,
        unsharpThreshold: 2
    });
    const blob = await pica.toBlob(result, 'image/jpeg', 0.7);
    const blobResult = await convertBlobToBinaryString(blob);
    return ({ file, result: btoa(blobResult) });
}

function skipResizing (file, body) {
    return new Promise(resolve => resolve({ file, result: body }));
}

function convertBlobToBinaryString(blob) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
            resolve(reader.result);
        }

        reader.onabort = () => {
            reject(new Error('Reading blob aborted'));
        }

        reader.onerror = () => {
            reject(new Error('Error reading blob'));
        }

        reader.readAsBinaryString(blob);
    });
}

function calculateSafeInputCanvasSize(inputImageWidth, inputImageHeight) {
    const result = { width: inputImageWidth, height: inputImageHeight };

    if (inputImageWidth > MAX_INPUT_CANVAS_SAFE_SIZE || inputImageHeight > MAX_INPUT_CANVAS_SAFE_SIZE) {
        const aspectRatio = inputImageWidth / inputImageHeight;
        if (aspectRatio > 1) {
            result.width = MAX_INPUT_CANVAS_SAFE_SIZE;
            result.height = MAX_INPUT_CANVAS_SAFE_SIZE / aspectRatio;
            return result;
        }
        result.height = MAX_INPUT_CANVAS_SAFE_SIZE;
        result.width = MAX_INPUT_CANVAS_SAFE_SIZE * aspectRatio;
        return result;
    }
    return result;
}
