import React, {  useState } from "react";
import { useIntl } from "react-intl";
import classnames from "classnames";
import { useHistory } from "react-router-dom";
import {  shallowEqual, useDispatch, useSelector } from "react-redux";

import SvgButtonBarClose from "../../svg/ButtonBarClose"
import { resetLoadedCase } from "redux/thunks/caseThunks";
import { triggerSaveOnAccordian } from "redux/actions/saveComponentValueAction";
import ReactModal from "react-modal";
import BaseButton from "components/common/BaseButton";

const ButtonClose = ({ buttonSize }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const history = useHistory();

    const [saveModalOpen, setSaveModalOpen] = useState(false);

    const connection = useSelector(state => state.connection, shallowEqual);
    const hasUnsavedChanges = useSelector(state => state.saveComponentValue.componentsToSave.length > 0, shallowEqual);

    const closeCase = () => {
        if (hasUnsavedChanges) {
            setSaveModalOpen(true);
            dispatch(triggerSaveOnAccordian(null));
        } else {
            setSaveModalOpen(false);
            dispatch(resetLoadedCase());

            connection ? history.goBack() : history.push('/dashboard');
        }
    }

    return (
        <>
            <button
                id="buttonClose"
                type="button"
                onClick={closeCase}
                className={classnames("button", "button--icon-only")}
            >
                <SvgButtonBarClose size={buttonSize} title={intl.formatMessage({ id: "formRenderer.close" })} />
            </button>
            <ReactModal
                isOpen={saveModalOpen}
                className="react-modal approveai-container"
                overlayClassName="react-modal-overlay"
            >
                <div className="react-modal__body update-modal__body">
                    <h2>{intl.formatMessage({ id: "savingChanges" })}</h2>
                </div>
                <div className="react-modal__footer">
                    <BaseButton
                        disabled={hasUnsavedChanges}
                        handleSubmit={closeCase}
                        text={intl.formatMessage({ id: "formRenderer.close" })}
                    />
                </div>
            </ReactModal>
        </>
    );
}

export default ButtonClose;
