import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Select from 'react-select'
import queryString from 'query-string'
import BaseButton from './BaseButton'
import SelectStyles from '../formcomponents/ReactSelectStyles'
import DateRangeFilter from './DateRangeFilter'
import { getAppliedFilters, getFilterQuery, getQueryFiltersFromURL } from './helpers/filterHelpers'
import classNames from 'classnames'
import { useIntl } from 'react-intl'

export default function Filter() {
  const history = useHistory()
  const intl = useIntl()

  const mainFilterRef = useRef(null)
  const nestedFilterRef = useRef(null)

  const [currentMainFilter, setCurrentMainFilter] = useState('')
  const [filters, setFilters] = useState([])

  const allFilters = useSelector(state => state.filters?.filterGroups || {})
  const appliedFilters = useSelector(state => getAppliedFilters(state.filters?.filterGroups))

  useEffect(() => {
    const initialFilters = getQueryFiltersFromURL()
    const filterArray = Object.entries(initialFilters).map(([mainFilter, nestedFilter]) => ({ mainFilter, nestedFilter }))
    setFilters(filterArray)

    return () => {
      setCurrentMainFilter('')
      setFilters([])
    }
  }, [])

  function focusSelect(ref) {
    ref.current?.focus()
  }

  function navigateOnFilterChange(updatedFilters) {
    const filteredParams = Object.fromEntries(
      Object.entries(updatedFilters).filter(([_, value]) => value)
    )

    const params = queryString.parse(history.location.search)
    delete params.page

    if (Object.keys(filteredParams).length === 0) {
      delete params.filters
    } else {
      params.filters = getFilterQuery(filteredParams)
    }

    history.push('?' + queryString.stringify(params))
  }

  function handleFilterRemoval(filter, filtersList) {
    return filtersList
      .filter(e => e.mainFilter !== filter.mainFilter)
      .reduce((acc, curr) => ({
        ...acc,
        [curr.mainFilter]: curr.nestedFilter,
      }), {})
  }

  function handleDateChange(dateObject, name) {
    const filtersFromURL = getQueryFiltersFromURL()
    const formattedDate = dateObject.dateObject.toISOString().split('T')[0]
    filtersFromURL[name] = formattedDate

    setFilters(prevFilters => {
      const updatedFilters = prevFilters.filter(el => el.mainFilter !== name)

      return [...updatedFilters, { mainFilter: name, nestedFilter: formattedDate }]
    })

    navigateOnFilterChange(filtersFromURL)
  }

  function handleSelectChange(e, isMainFilter = true) {
    if (!e) {
      setFilters([])
      setCurrentMainFilter('')
      return
    }

    if (isMainFilter) {
      setCurrentMainFilter(e.value)
      if (!filters.some(f => f.mainFilter === e.value)) {
        setFilters([...filters, { mainFilter: e.value, nestedFilter: '' }])
      }
    } else {
      const updatedFilters = filters.map(f =>
        f.mainFilter === currentMainFilter ? { ...f, nestedFilter: e.value } : f
      )
      setFilters(updatedFilters)
      navigateOnFilterChange(
        updatedFilters.reduce((acc, curr) => ({ ...acc, [curr.mainFilter]: curr.nestedFilter }), {})
      )
    }
  }

  function renderFilterChips() {
    const uniqueFilters = filters.reduce((acc, current) => {

      const isUniqueFilter = !acc.some(item => item.mainFilter === current.mainFilter)
      if (isUniqueFilter && current.nestedFilter) {
        acc.push(current)
      }
      return acc
    }, [])

    if (uniqueFilters.length === 0) return null

    return (
      <div className='button__chips_container'>
        {uniqueFilters.map(f => {
          const translatedText = intl.formatMessage({ id: 'filter.' + f.mainFilter })
          const filterTitle = translatedText !== 'filter.' + f.mainFilter ? translatedText : f.mainFilter
          const nestedFilter = f.nestedFilter === 'naminspection' ? 'Inspection Americas' : f.nestedFilter

          return (
            f.mainFilter && f.mainFilter !== 'date' && f.nestedFilter && (
              <button
                key={f.mainFilter}
                className='filter__chip'
                onClick={() => {
                  const updatedFilters = uniqueFilters.filter(filter => filter.mainFilter !== f.mainFilter)
                  setFilters(updatedFilters)
                  setCurrentMainFilter(currentMainFilter === f.mainFilter ? '' : currentMainFilter)
                  navigateOnFilterChange(handleFilterRemoval(f, uniqueFilters))
                }}
              >
                <span className='filter__text-title'>{filterTitle}:</span>
                <span className='filter__text'>{nestedFilter}</span>
              </button>
            )
          )
        })}
        {renderDateChips(uniqueFilters)}
      </div>
    )
  }

  function renderDateChips(list) {
    const dateFilters = list.find(f => f.mainFilter === 'date' && f.nestedFilter)
    if (!dateFilters) return null

    const [from, to] = dateFilters.nestedFilter.split(', ').map(date => date.split(': ')[1])
    const chips = []

    if (from) {
      chips.push(
        <button
          key='from-date'
          className='filter__chip'
          onClick={() => handleDateRemoval('from', list)}
        >
          <span className='filter__text'>From: {from}</span>
        </button>
      )
    }

    if (to) {
      chips.push(
        <button
          key='to-date'
          className='filter__chip'
          onClick={() => handleDateRemoval('to', list)}
        >
          <span className='filter__text'>To: {to}</span>
        </button>
      )
    }

    return chips
  }

  function handleDateRemoval(type, list) {
    const updatedFilters = list.map(f => {
      if (f.mainFilter === 'date') {
        const parts = f.nestedFilter.split(', ')
        parts[type === 'from' ? 0 : 1] = `${type}: undefined`
        return { ...f, nestedFilter: parts.join(', ') }
      }
      return f
    })
    setFilters(updatedFilters)
    navigateOnFilterChange(handleFilterRemoval({ mainFilter: 'date' }, list))
  }

  const hasAnyFilters = filters.length > 0 || appliedFilters.length > 0

  return (
    <div className='filter__container'>
      <div className='filter__options'>
        <div
          className={classNames('filter__select_wrapper', {
            'filter__select_wrapper--full-width': hasAnyFilters && currentMainFilter !== 'date',
            'filter__select_wrapper--block': currentMainFilter === 'date',
          })}
        >
          <div className={currentMainFilter !== 'date' ? 'dropdowns__wrapper' : ''}>
            <div className='form_control'>
              <label onClick={() => focusSelect(mainFilterRef)}>{intl.formatMessage({ id: 'filter.filters' })}</label>
              <Select
                ref={mainFilterRef}
                value={currentMainFilter ? { label: intl.formatMessage({ id: 'filter.' + currentMainFilter }), value: currentMainFilter } : null}
                options={Object.keys(allFilters).map(filter => ({
                  label: intl.formatMessage({ id: `filter.${filter}` }),
                  value: filter,
                }))}
                styles={SelectStyles}
                onChange={e => handleSelectChange(e)}
              />
            </div>

            {currentMainFilter !== 'date' ? (
              <div className='form_control'>
                <label onClick={() => focusSelect(nestedFilterRef)}>{intl.formatMessage({ id: 'filter.subcategory' })}</label>
                <Select
                  ref={nestedFilterRef}
                  options={allFilters[currentMainFilter]?.items
                    ?.filter(item => item.value)
                    .map(item => ({
                      label: item.value === 'naminspection' ? 'Inspection Americas' : item.value,
                      value: item.value,
                    }))
                  }
                  value={filters.find(f => f.mainFilter === currentMainFilter)?.nestedFilter || null}
                  styles={SelectStyles}
                  onChange={e => handleSelectChange(e, false)}
                />
              </div>
            ) : (
              <DateRangeFilter
                onDateChange={handleDateChange}
                appliedFilters={appliedFilters}
              />
            )}
          </div>
          {renderFilterChips()}
        </div>
        <div className='filter__actions'>
          <BaseButton
            handleSubmit={() => {
              if (!hasAnyFilters) return
              setFilters([])
              setCurrentMainFilter('')
              navigateOnFilterChange({})
            }}
            disabled={!hasAnyFilters}
            text={intl.formatMessage({ id: 'filter.clearAllFilters' })}
            backgroundColor='gray'
            style={{ width: '100%' }}
          />
        </div>
      </div>

    </div>
  )
}
