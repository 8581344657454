import queryString from 'query-string'

function isValidDate(date) {
  const verifyDate = new Date(date)
  return !Number.isNaN(verifyDate.getTime())
}

function getFilterQuery(filters) {
  const queryParts = Object.keys(filters).map(
    queryGroupName => `${queryGroupName}:${filters[queryGroupName]}`
  )
  return queryParts.join(',')
}


function getQueryFiltersFromURL() {
  const URLSearch = queryString.parse(window.location.search)
  const filters = {}
  if (URLSearch.filters) {
    URLSearch.filters.split(',').forEach(filterString => {
      const [key, value] = filterString.split(':')
      filters[key] = decodeURI(value)
    })
  }
  return filters
}

function getAppliedFilters(filters = {}) {
  if (!filters || typeof filters !== 'object') return []

  let appliedFilters = Object.keys(filters)
    .filter(groupName => groupName !== 'date' && filters[groupName].items)
    .flatMap(groupName =>
      filters[groupName].items
        .filter(item => item.isSelected)
        .map(item => ({ ...item, groupName }))
    )

  const URLFilters = getQueryFiltersFromURL()
  if (URLFilters.from && isValidDate(URLFilters.from)) {
    appliedFilters.push({
      value: `from ${new Date(URLFilters.from).toLocaleDateString()}`,
      groupName: 'from',
      isSelected: true,
      date: new Date(URLFilters.from),
    })
  }
  if (URLFilters.to && isValidDate(URLFilters.to)) {
    appliedFilters.push({
      value: `to ${new Date(URLFilters.to).toLocaleDateString()}`,
      groupName: 'to',
      isSelected: true,
      date: new Date(URLFilters.to),
    })
  }

  return appliedFilters
}

export { isValidDate, getFilterQuery, getAppliedFilters, getQueryFiltersFromURL }
