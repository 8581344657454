const ui = {
  isNavigationOpen: false,
  isHeaderSearchOpen: false,
  isHeaderSettingsOpen: false,
};
let language = "en";

if (
  localStorage.getItem("currentLang") !== undefined &&
  localStorage.getItem("currentLang") !== null
) {
  language = localStorage.getItem("currentLang");
}

const formTemplate = "";
const isSaving = false;

const swReducers = {
  serviceWorkerInitialized: false,
  serviceWorkerUpdated: false,
  serviceWorkerRegistration: null,
};

const connection = true;

const requiredList = [];

const caseRelated = {
  cases: [],
  total: 0,
  loading: false,
};

const filters = {
  filterGroups: {},
};

const saveComponentValue = {
  components: [],
  componentsBeforeSave: [],
  componentsToSave: [],
  useAutomaticFigureNumbering: false,
  images: [],
  quicklinks: {
    target: {},
    positions: []
  },
  scrollTarget: {},
  livePreviewTarget: '',
  validationErrors: [],
  disableAutoScroll: false,
  formtemplateId: null,
  caseWasSaved: true,
  imageToDelete: null,
  hasAgreedToSync: false,
};

const isRailwayWheelsetBearing = [];

const isConfidential = false;
const excludeISOAppendix = false;
const pageBreakEachBearing = false;
const showSummaryAtTop = false;
const activeTab = 0;
const bearingsExpanded = {
  isBearingsExpanded: false,
  bearingsExpandedState: "",
  isBearingsSaving: false
}

const currentCase = {
  case: null,
  updates: []
};
const currentUser = {};
const currentCaseAccess = {};
const currentFormConfig = null;


export default {
  ui,
  caseRelated,
  filters,
  saveComponentValue,
  language,
  requiredList,
  connection,
  formTemplate,
  isSaving,
  swReducers,
  isRailwayWheelsetBearing,
  isConfidential,
  excludeISOAppendix,
  pageBreakEachBearing,
  currentCase,
  currentUser,
  showSummaryAtTop,
  bearingsExpanded,
  activeTab,
  currentCaseAccess,
  currentFormConfig
};
