import React from 'react'
import PropTypes from 'prop-types'

const MainContainer = (props) => {
  return (
    <section className="page-layout__container">
    { props.header }

    {/* <breadcrumb v-if="useBreadcrumb" /> */}

    <section className="page-layout__main-container">
      { props.pageTitle }

      <div className={!props.filter ? 'page-layout__container' : ''}>
        { props.filter }
        
        <div className="page-layout__content">
          { props.children }
        </div>
      </div>
    </section>

    { props.footer }
  </section>
  )
}

MainContainer.propTypes = {
  header: PropTypes.object,
  pageTitle: PropTypes.object,
  main: PropTypes.object,
  filter: PropTypes.object,
  footer: PropTypes.object
}

export default MainContainer
