const TOGGLE_NAVIGATION = "TOGGLE_NAVIGATION";
const POPULATE_CASES = "POPULATE_CASES";
const TOGGLE_CASES_LOADING = "TOGGLE_CASES_LOADING";
const POPULATE_FILTERS = "POPULATE_FILTERS";
const TOGGLE_FILTER_GROUP_IS_OPEN = "TOGGLE_FILTER_GROUP_IS_OPEN";
const SEARCH_FILTER_ITEM = "SEARCH_FILTER_ITEM";
const SAVE_COMPONENT_VALUE = "SAVE_COMPONENT_VALUE";
const SAVE_COMPONENT_VALUES = "SAVE_COMPONENT_VALUES";
const SET_ALL_ANSWERS = "SET_ALL_ANSWERS";
const SET_ANSWER_TO_SAVE = "SET_ANSWER_TO_SAVE";
const REMOVE_ANSWER_TO_SAVE = "REMOVE_ANSWER_TO_SAVE";
const REMOVE_ANSWERS_TO_SAVE = "REMOVE_ANSWERS_TO_SAVE";
const CLEAR_ANSWERS_TO_SAVE = "CLEAR_ANSWERS_TO_SAVE";
const TRIGGER_SAVE_ON_ACCORDIAN = "TRIGGER_SAVE_ON_ACCORDIAN";
const SET_ALL_ANSWERS_BEFORE_SAVE = "SET_ALL_ANSWERS_BEFORE_SAVE";
const SET_CURRENT_LANGUAGE = "SET_CURRENT_LANGUAGE";
const SET_REQUIRED_LIST = "SET_REQUIRED_LIST";
const REMOVE_REQUIRED_LIST_BEARING = "REMOVE_REQUIRED_LIST_BEARING";
const REMOVE_REQUIREDLIST_KEYS = "REMOVE_REQUIREDLIST_KEYS";
const SET_CONNECTION = "SET_CONNECTION";
const SET_CURRENT_FORMTEMPLATE = "SET_CURRENT_FORMTEMPLATE";
const SET_IS_SAVING = "SET_IS_SAVING";
const REMOVE_COMPONENT_VALUE = "REMOVE_COMPONENT_VALUE";
const SET_IS_RAILWAY_WHEELSET_BEARING = "SET_WHEELSET_BEARING";
const RESET_IS_RAILWAY_WHEELSET_BEARING = "RESET_IS_RAILWAY_WHEELSET_BEARING";
const SET_CONFIDENTIAL = "SET_CONFIDENTIAL";
const USE_AUTOMATIC_FIGURENUMBERING = "USE_AUTOMATIC_FIGURENUMBERING";
const SET_EXCLUDE_ISO_APPENDIX = "SET_EXCLUDE_ISO_APPENDIX";
const SET_PAGE_BREAK_EACH_BEARING = "SET_PAGE_BREAK_EACH_BEARING";
const SET_CURRENT_CASE = "SET_CURRENT_CASE";
const SET_CURRENT_CASE_STATUS = "SET_CURRENT_CASE_STATUS";
const SET_CURRENT_ADD_CASE_TO_SAVE = "SET_CURRENT_ADD_CASE_TO_SAVE";
const SET_CURRENT_REMOVE_CASE_TO_SAVE = "SET_CURRENT_REMOVE_CASE_TO_SAVE";
const SET_CURRENT_USER = "SET_CURRENT_USER";
const SELECT_QUICKLINK = "SELECT_QUICKLINK";
const RECORD_QUICKLINK_POSITION = "RECORD_QUICKLINK_POSITION";
const SET_SCROLL_TARGET = "SET_SCROLL_TARGET";
const SET_DISABLE_AUTOSCROLL = "SET_DISABLE_AUTOSCROLL";
const SET_SUMMARY_ATTOP = "SET_SUMMARY_ATTOP";
const SET_ISBEARINGS_EXPANDED = "SET_ISBEARINGS_EXPANDED";
const SET_ISBEARINGS_SAVING = "SET_ISBEARINGS_SAVING";
const SET_RESETBEARINGS_EXPANDED = "SET_RESETBEARINGS_EXPANDED";
const SET_BEARINGS_EXPANDEDSTATE = "SET_BEARINGS_EXPANDEDSTATE";
const SET_ACTIVE_TAB = "SET_ACTIVE_TAB";
const SET_VALIDATION_ERRORS = "SET_VALIDATION_ERRORS";
const REMOVE_VALIDATION_ERRORS_BEARING = "REMOVE_VALIDATION_ERRORS_BEARING";
const REMOVE_VALIDATION_ERROR = "REMOVE_VALIDATION_ERROR";
const ADD_VALIDATION_ERROR = "ADD_VALIDATION_ERROR";
const SET_CURRENTCASE_FORMTEMPLATE = "SET_CURRENTCASE_FORMTEMPLATE";
const SET_CURRENTCASE_ACCESS = "SET_CURRENTCASE_ACCESS";
const SET_CURRENT_FORMCONFIG = "SET_CURRENT_FORMCONFIG";
const DISMISS_ANSWER_ERROR = "DISMISS_ANSWER_ERROR";
const SET_LIVEPREVIEW_TARGET = "SET_LIVEPREVIEW_TARGET";
const SET_SELECTED_BEARING = "SET_SELECTED_BEARING";
const SET_IMAGE_TO_DELETE = "SET_IMAGE_FOR_DELETION";
const SET_CONVERTED_TO_BDA_CASEID = "SET_CONVERTED_TO_BDA_CASEID";
const SET_MOVED_BEARINGS = "SET_MOVED_BEARINGS";
const SET_HAS_AGREED_TO_SYNC = "SET_HAS_AGREED_TO_SYNC"; /** global state variable helper for syncing offline changes before submitting a report */

export {
  TOGGLE_NAVIGATION,
  POPULATE_CASES,
  TOGGLE_CASES_LOADING,
  POPULATE_FILTERS,
  TOGGLE_FILTER_GROUP_IS_OPEN,
  SEARCH_FILTER_ITEM,
  SAVE_COMPONENT_VALUE,
  SAVE_COMPONENT_VALUES,
  SET_ALL_ANSWERS,
  SET_ANSWER_TO_SAVE,
  REMOVE_ANSWER_TO_SAVE,
  REMOVE_ANSWERS_TO_SAVE,
  CLEAR_ANSWERS_TO_SAVE,
  TRIGGER_SAVE_ON_ACCORDIAN,
  SET_ALL_ANSWERS_BEFORE_SAVE,
  SET_CURRENT_LANGUAGE,
  SET_REQUIRED_LIST,
  REMOVE_REQUIRED_LIST_BEARING,
  REMOVE_REQUIREDLIST_KEYS,
  SET_CONNECTION,
  REMOVE_COMPONENT_VALUE,
  SET_CURRENT_FORMTEMPLATE,
  SET_IS_SAVING,
  SET_IS_RAILWAY_WHEELSET_BEARING,
  RESET_IS_RAILWAY_WHEELSET_BEARING,
  SET_CONFIDENTIAL,
  SET_EXCLUDE_ISO_APPENDIX,
  USE_AUTOMATIC_FIGURENUMBERING,
  SET_PAGE_BREAK_EACH_BEARING,
  SET_CURRENT_CASE,
  SET_CURRENT_CASE_STATUS,
  SET_CURRENT_ADD_CASE_TO_SAVE,
  SET_CURRENT_REMOVE_CASE_TO_SAVE,
  SET_CURRENT_USER,
  SELECT_QUICKLINK,
  RECORD_QUICKLINK_POSITION,
  SET_SCROLL_TARGET,
  SET_DISABLE_AUTOSCROLL,
  SET_SUMMARY_ATTOP,
  SET_ISBEARINGS_EXPANDED,
  SET_ISBEARINGS_SAVING,
  SET_BEARINGS_EXPANDEDSTATE,
  SET_RESETBEARINGS_EXPANDED,
  SET_ACTIVE_TAB,
  SET_VALIDATION_ERRORS,
  REMOVE_VALIDATION_ERRORS_BEARING,
  REMOVE_VALIDATION_ERROR,
  ADD_VALIDATION_ERROR,
  SET_CURRENTCASE_FORMTEMPLATE,
  SET_CURRENTCASE_ACCESS,
  SET_CURRENT_FORMCONFIG,
  DISMISS_ANSWER_ERROR,
  SET_LIVEPREVIEW_TARGET,
  SET_SELECTED_BEARING,
  SET_IMAGE_TO_DELETE,
  SET_CONVERTED_TO_BDA_CASEID,
  SET_MOVED_BEARINGS,
  SET_HAS_AGREED_TO_SYNC,
};
