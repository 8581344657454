import React, { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import classnames from "classnames";
import ReactModal from "react-modal"

import SvgButtonBarSubmit from "./../../svg/ButtonBarSubmit";
import BaseButton from "components/common/BaseButton";
import CircleCloseFilterSvg from "components/svg/CircleCloseFilter";
import submitCase from "./../ButtonActions/submitCase";
import { saveAllAnswersAndValidate } from "redux/thunks/answerThunk";
import { setValidationErrors, setHasAgreedToSync } from "redux/actions/saveComponentValueAction";
import useOfflineBar from "../../../customHooks/useOfflineBar";

const ButtonSubmit = ({
    buttonSize,
    currentFormTemplateID,
    setCaseStatus,
    userCanEdit,
}) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const {
        connection,
        isSubmitting,
        setIsSubmitting,
        isShowingSyncbar,
    } = useOfflineBar();

    const [isModalOpen, setIsModalOpen] = useState(false);

    const isCaseStatusInDraft = useSelector(state => state.currentCase.case.status, shallowEqual) === 'draft';
    const currentCaseId = useSelector(state => state.currentCase.case.id, shallowEqual);
    const currentUser = useSelector(state => state.currentUser, shallowEqual);
    const answers = useSelector(state => state.saveComponentValue.components, shallowEqual);
    const isSaving = useSelector(state => state.isSaving);

    const openConfirmationModal = () => {
        if (!isSubmitting) {
            setIsSubmitting(true);
            dispatch(saveAllAnswersAndValidate(result => {
                dispatch(setValidationErrors(result.requiredList));
                if (!result.validationErrors) {
                    setIsModalOpen(true);
                } else {
                    setIsSubmitting(false);
                }
            }));
        }
    }

    const closeConformationModal = () => {
        setIsModalOpen(false);
        setIsSubmitting(false);
    }

    const onSubmitCase = () => {

        setIsModalOpen(false);

        submitCase(currentCaseId, answers, currentFormTemplateID, currentUser, intl, dispatch).then(response => {
            setIsSubmitting(false);
            setCaseStatus(response.caseStatus);
        });
    }

    const isDisabled = !connection || !userCanEdit || isSaving;

    return isCaseStatusInDraft && (
        <>
            <button
                id="buttonSubmit"
                type="button"
                onClick={openConfirmationModal}
                className={classnames("button", "button--icon-only", { "button--icon-saving": isSubmitting }, { "button--icon-disabled": isDisabled })}
                disabled={isDisabled}
            >
                <SvgButtonBarSubmit size={buttonSize} title={intl.formatMessage({ id: "formRenderer.submitForApproval" })} />
            </button>
            <ReactModal
                isOpen={isModalOpen}
                className="react-modal"
                overlayClassName="react-modal-overlay"
                onRequestClose={closeConformationModal}
            >
                <div className="react-modal__header">
                    <button className="react-modal__close-button" onClick={closeConformationModal}>
                        <CircleCloseFilterSvg />
                    </button>
                </div>
                <div className="react-modal__body">
                    <div className="react-modal__text">
                        {/* TODO - add translations */}
                        <FormattedMessage id={isShowingSyncbar ? 'You need to sync your offline work before submitting the report.' : "formRenderer.submitMessage"} />
                    </div>
                    <div className="react-modal__actions-container">
                        {isShowingSyncbar ? (
                            <BaseButton
                                handleSubmit={() => {
                                    dispatch(setHasAgreedToSync(true));
                                    setIsModalOpen(false);
                                    setIsSubmitting(false);
                                }}
                                text='SYNC NOW!'
                                backgroundColor="gray"
                                className="react-modal__action-button button--wide"
                            />
                        ) : (
                            <>
                                <BaseButton handleSubmit={onSubmitCase} text={intl.formatMessage({ id: "formRenderer.submitButton" })} backgroundColor="gray" className="react-modal__action-button button--wide" />
                                <BaseButton handleSubmit={closeConformationModal} text={intl.formatMessage({ id: "formRenderer.edit.cancel" })} backgroundColor="green" className="react-modal__action-button" />
                            </>
                        )}
                    </div>
                </div>
            </ReactModal>
        </>
    )
}

export default ButtonSubmit;